import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import {
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
} from 'firebase/firestore';
import { db } from '../../firebase';
import './AdminDashboard.css';

function AdminDashboard() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [schedules, setSchedules] = useState([]);
  const [newSchedule, setNewSchedule] = useState({
    date: '',
    colorName: '',
    colorPickerValue: '#ffffff',
    quote: '',
  });
  const [editingSchedule, setEditingSchedule] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch schedules from Firestore
  const fetchSchedules = async () => {
    setLoading(true); // Set loading before fetching
    try {
      const querySnapshot = await getDocs(collection(db, 'schedules'));
      const schedulesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSchedules(schedulesData);
    } catch (error) {
      console.error('Error fetching schedules:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSchedules();
  }, []);

  // Add a new schedule to Firestore
  const handleCreateSchedule = async (e) => {
    e.preventDefault();
    if (!newSchedule.date || !newSchedule.colorName || !newSchedule.quote) {
      alert('Please fill out all fields!');
      return;
    }
    try {
      const docRef = await addDoc(collection(db, 'schedules'), newSchedule);
      setSchedules((prev) => [
        ...prev,
        { id: docRef.id, ...newSchedule }, // Add the new schedule locally
      ]);
      setNewSchedule({
        date: '',
        colorName: '',
        colorPickerValue: '#ffffff',
        quote: '',
      });
    } catch (error) {
      console.error('Error creating schedule:', error);
    }
  };

  // Update an existing schedule in Firestore
  const handleEditSchedule = async (e) => {
    e.preventDefault();
    if (!editingSchedule.date || !editingSchedule.colorName || !editingSchedule.quote) {
      alert('Please fill out all fields!');
      return;
    }
    try {
      const scheduleDoc = doc(db, 'schedules', editingSchedule.id);
      await updateDoc(scheduleDoc, editingSchedule);
      setSchedules((prev) =>
        prev.map((schedule) =>
          schedule.id === editingSchedule.id ? editingSchedule : schedule
        )
      );
      setEditingSchedule(null);
    } catch (error) {
      console.error('Error updating schedule:', error);
    }
  };

  // Delete a schedule from Firestore
  const handleDeleteSchedule = async (id) => {
    try {
      await deleteDoc(doc(db, 'schedules', id));
      setSchedules((prev) => prev.filter((schedule) => schedule.id !== id));
    } catch (error) {
      console.error('Error deleting schedule:', error);
    }
  };

  // Logout functionality
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login'); // Redirect to the login page after logout
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  if (loading) return <p>Loading schedules...</p>;

  return (
    <div className="admin-dashboard">
      <header className="dashboard-header">
        <button className="home-btn" onClick={() => navigate('/')}>
          Home
        </button>
        <h2>Admin Dashboard</h2>
        <button onClick={handleLogout} className="logout-btn">
          Logout
        </button>
      </header>
      {editingSchedule ? (
        <form className="admin-form" onSubmit={handleEditSchedule}>
          <label>Date:</label>
          <input
            type="date"
            value={editingSchedule.date}
            onChange={(e) =>
              setEditingSchedule({ ...editingSchedule, date: e.target.value })
            }
            required
          />
          <label>Color of the Day:</label>
          <input
            type="text"
            value={editingSchedule.colorName}
            onChange={(e) =>
              setEditingSchedule({
                ...editingSchedule,
                colorName: e.target.value,
              })
            }
            required
          />
          <label>Pick a Color:</label>
          <input
            type="color"
            value={editingSchedule.colorPickerValue}
            onChange={(e) =>
              setEditingSchedule({
                ...editingSchedule,
                colorPickerValue: e.target.value,
              })
            }
            required
          />
          <label>Quote:</label>
          <textarea
            value={editingSchedule.quote}
            onChange={(e) =>
              setEditingSchedule({
                ...editingSchedule,
                quote: e.target.value,
              })
            }
            required
          ></textarea>
          <button type="submit">Update Schedule</button>
        </form>
      ) : (
        <form className="admin-form" onSubmit={handleCreateSchedule}>
          <label>Date:</label>
          <input
            type="date"
            value={newSchedule.date}
            onChange={(e) =>
              setNewSchedule({ ...newSchedule, date: e.target.value })
            }
            required
          />
          <label>Color of the Day:</label>
          <input
            type="text"
            value={newSchedule.colorName}
            onChange={(e) =>
              setNewSchedule({ ...newSchedule, colorName: e.target.value })
            }
            required
          />
          <label>Pick a Color:</label>
          <input
            type="color"
            value={newSchedule.colorPickerValue}
            onChange={(e) =>
              setNewSchedule({
                ...newSchedule,
                colorPickerValue: e.target.value,
              })
            }
            required
          />
          <label>Quote:</label>
          <textarea
            value={newSchedule.quote}
            onChange={(e) =>
              setNewSchedule({ ...newSchedule, quote: e.target.value })
            }
            required
          ></textarea>
          <button type="submit">Add Schedule</button>
        </form>
      )}
      <div className="schedule-header">
        <h3>Scheduled Colors and Quotes</h3>
        <button className="refresh-btn" onClick={fetchSchedules}>
          Refresh
        </button>
      </div>
      <ul className="schedule-list">
        {schedules.map((schedule) => (
          <li key={schedule.id} className="schedule-item">
            <div
              className="color-box"
              style={{ backgroundColor: schedule.colorPickerValue }}
            ></div>
            <div className="schedule-details">
              <span>Date: {schedule.date}</span>
              <span>Color of the Day: {schedule.colorName}</span>
              <span>Quote: {schedule.quote}</span>
              <span>Color Code: {schedule.colorPickerValue}</span>
            </div>
            <button
              className="edit-btn"
              onClick={() => setEditingSchedule(schedule)}
            >
              Edit
            </button>
            <button
              className="delete-btn"
              onClick={() => handleDeleteSchedule(schedule.id)}
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AdminDashboard;
