import React, { useEffect } from "react";
import "./GoogleAd.css"; // Optional if you have specific styles for ads

const GoogleAd = ({ adSlot, style }) => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("AdSense Error:", e);
    }
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block", textAlign: "center", ...style }}
      data-ad-client="ca-pub-6698636577007643" // Replace with your AdSense client ID
      data-ad-slot={adSlot} // Replace with the specific Ad Slot ID
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  );
};

export default GoogleAd;
