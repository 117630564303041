// src/routes/AppRouter.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MainPage from '../components/MainPage/MainPage';
import AdminDashboard from '../components/AdminDashboard/AdminDashboard';
import Login from '../components/Login/Login';
import PrivateRoute from './PrivateRoute';

const AppRouter = () => (
  <Routes>
    <Route path="/" element={<MainPage />} />
    <Route path="/login" element={<Login />} />
    <Route
      path="/admin"
      element={
        <PrivateRoute>
          <AdminDashboard />
        </PrivateRoute>
      }
    />
  </Routes>
);

export default AppRouter;
