// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCIJFjWVFAC-yzc9AF4CiNoPwDRvnej09M",
    authDomain: "colorurday-afb7f.firebaseapp.com",
    projectId: "colorurday-afb7f",
    storageBucket: "colorurday-afb7f.firebasestorage.app",
    messagingSenderId: "577482370024",
    appId: "1:577482370024:web:42eb37b71d021337b4d25d",
    measurementId: "G-GK646GZL3C"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
