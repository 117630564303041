// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Example Google Ad Styling */
.adsbygoogle {
    margin: 20px 0; /* Add vertical spacing */
    text-align: center;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/GoogleAd/GoogleAd.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;IACI,cAAc,EAAE,yBAAyB;IACzC,kBAAkB;EACpB","sourcesContent":["/* Example Google Ad Styling */\n.adsbygoogle {\n    margin: 20px 0; /* Add vertical spacing */\n    text-align: center;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
